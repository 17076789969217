@import '../../../css/colors-pictown.scss';

.category-list {
  text-align: center;
  background-color: $white;
  color: $dark-grey;
  border-radius: 0.3em;
  overflow-wrap: break-word;

  // Shadow effect (Pixel)
  box-shadow: 0px 0px 20px $neon-pink-shadow;
  border: 2px solid $neon-pink-border;


  .categories-item {
    cursor: pointer;
    font-size: 1.1em;
    padding: 1em;
    border-bottom: solid 0.1em $fade-grey;
    font-weight: 600;

    &:hover {
      background-color: $hover-grey;
    }

    &.focused {
      background-color: $pic-red;
      color: $white;
    }
  }
}

.articles-manager {

  .article-item {
    display: inline-block;
    width: 6em;
    height: 6em;
    border-radius: 1.3em;
    background-color: $white;
    color: $dark-grey;
    font-weight: 600;
    text-align: center;
    margin: 0 1.1em 1.1em 1.1em;
    vertical-align: middle;
    overflow-wrap: break-word;

    overflow: hidden;

    // Shadow effect (Pixel)
    box-shadow: 0px 0px 20px $neon-yellow-shadow;
    border: 2px solid $neon-yellow-border;

    img{
      width: 6em;
      height: 6em;
    }

    .no-image {
      margin-top: 25%;
    }
  }
}

.payment-container {
  background-color: $white;
  color: $dark-grey;
  border-radius: 0.3em;
  padding: 1em;

  // Shadow effect (Pixel)
  box-shadow: 0px 0px 20px $neon-pink-shadow;
  border: 2px solid $neon-pink-border;

  .title {
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
  }

  .payment-articles {
    padding: 10px 0px 10px 0px;

    .payment-header{
      display: table;
      width: 100%;
      text-align: center;
      font-weight: 600;

      .cell-header {
        display: table-cell;
        border-bottom: solid 0.1em $fade-grey;
        padding: 0.5em;
        width: 10%;
        font-weight: bold;
        font-size: 16px;
        &.large {
          width: 70%;
        }
      }
    }
  }

  .selected-article-item {
    display: table;
    width: 100%;
    text-align: center;
    font-weight: 400;
    overflow-wrap: break-word;

    .cell {
      display: table-cell;
      border-bottom: solid 0.1em $fade-grey;
      padding: 0.5em;
      text-align: center;
    }

    .item-qte {
      width: 10%;
      font-weight: bold;
      font-size: 16px;
    }

    .item-name {
      width: 70%;
      font-weight: bold;
      font-size: 16px;
    }

    .item-price {
      font-weight: bold;
      font-size: 16px;
    }
    .item-trash {
      width: 10%;
      i.icon {
        color: $pic-red;
        &:hover {
          color: $pic-red-hover;
        }
      }
    }
  }
}

.payment-footer {
  .button-cancel {
    &.ui.button {
      background-color: $pic-blue;
      color: $white;
      &:hover {
        background-color: $pic-blue-hover;
      }
      &:focus {
        background-color: $pic-blue;
      }
    }
  }
  .payment-information {
    text-align: center;
    margin-top: 15px;
    background-color: $fade-grey;
    padding: 0.8em;
    border-radius: 0.3em;
    font-weight: 600;
    color: $dark-grey;

    &.error {
      background-color: $pic-red-error;
      color: $dark-grey;
    }

    &.success {
      background-color: $green-success;
      color: $dark-grey;
    }
  }
}