$black : #000000;
$white: #F7AC6C;
$dark-grey: #2f2f2f;
$pic-blue: #F9DCAD;
$pic-blue-hover: #ffffffcc;
$pic-red: #EE6D50;
$pic-red-hover: #c1543b;
$light-grey: rgba(198, 211, 225, 0.25);
$fade-grey: rgba(255, 255, 255, 0.1);
$hover-grey: rgba(255, 255, 255, 0.1);
$green-success: rgba(33, 178, 50, 0.8);
// Pixel additions
$neon-pink-shadow: #883F1A;
$neon-pink-border: #B2291C;
$neon-yellow-shadow: #9d5e6d;
$neon-yellow-border: #e1899e;
$pic-red-error: rgb(209, 78, 78);