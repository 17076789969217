@import "../../../css/colors-pictown.scss";

@font-face {
  font-family:'WESTERN';
  src: url('../../../fonts/Marlboro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  font-family: 'WESTERN';
  letter-spacing: 0.01em;
}

.modal-header {
  text-align: center;
  border:none !important;
}
.modal-container {
  width: 100%;
  padding: 10px;
  color: $black;
  text-align: center;

  .modal-input {
    width: 100%;
    margin: 10px 0;

    &.ui.input input {
      border: 1px solid $light-grey;
    }
  }

  .modal-pad {
    .pad-row {
      .pad-button {
        margin: 5px;
      }
    }
  }

  .modal-sales-locations {
    margin-top: 10px;

    .location-button {
      margin: auto 5px;
    }
  }

  .modal-buyer-purchases {
    display: table;
    width: 100%;
    text-align: center;
    font-weight: 600;
    overflow-wrap: break-word;
    color: $black;
    margin-bottom: 1em;

    .buyer-row {
      display: table-row;
      .cell {
        display: table-cell;
        border-bottom: solid 0.1em $fade-grey;
        padding: 0.5em;
        text-align: center;

        &.qte {
          width: 10%;
        }

        &.name {
          width: 70%;
        }

        &.price {
          width: 10%;
        }

        &.trash {
          width: 10%;

          i.icon {
            color: $pic-red;
            &:hover {
              color: $pic-red-hover;
            }
          }
        }
      }
    }
  }
}